import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import Img from 'gatsby-image';
import  { ExternalLink } from '../components/link';

export default ({ file, title, className }) => {
  const data = useStaticQuery(graphql`
    query {
      allContentfulAsset {
        nodes {
          file {
            fileName
            url
          }
          localFile {
            publicURL
            childImageSharp {
              fluid(maxWidth: 1200) {
                ...GatsbyImageSharpFluid_withWebp_noBase64
              }
            }
          }
          fluid(maxWidth: 1200) {
            ...GatsbyContentfulFluid_withWebp_noBase64
          }
        }
      }
    }
  `);

  const asset = data.allContentfulAsset.nodes.find(
    n => n.file && n.file.fileName === file.fileName
  );

  // Handle different media types differently
  switch(file.contentType){
    // Video types
    case 'video/mp4':
      return <Video asset={asset} file={file} className={className} />;


    // Image types
    case 'image/jpeg':
    case 'image/png':
      return <Image asset={asset} file={file} title={title} className={className} />;


    // General files
    default: // Fallback, use simple link to the asset
    {
      const url = asset.localFile?.publicURL || asset.file.url;

      return <ExternalLink to={url}>{title}</ExternalLink>
    }
  }
};

const Video = ({ asset, file, className }) => {
  return asset ? (
    <video controls playsInline className={className}>
      <source
        src={asset.localFile ? asset.localFile.publicURL : asset.file.url}
        type="video/mp4"
      />
    </video>
  ) : (
    <video controls playsInline className={className}>
      <source src={file.url} type="video/mp4" />
    </video>
  );
};

const Image = ({ asset, file, title, className }) => {
  return asset ? (
    <Img
      className={className}
      fluid={
        asset.localFile ? asset.localFile.childImageSharp.fluid : asset.fluid
      }
      alt={title}
    />
  ) : (
    <img src={file.url} alt={title} className={className} />
  );
};
