import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';

export default ({ file, className, children }) => {
  const data = useStaticQuery(graphql`
    query {
      allContentfulAsset {
        nodes {
          file {
            fileName
            url
          }
          localFile {
            publicURL
          }
        }
      }
    }
  `);

  const asset = data.allContentfulAsset.nodes.find(
    n => n.file && n.file.fileName === file.fileName
  );

  return asset ? (
    <Link
      url={asset.localFile ? asset.localFile.publicURL : asset.file.url}
      className={className}
    >
      {children}
    </Link>
  ) : (
    <Link url={file.url} className={className}>
      {children}
    </Link>
  );
};

const Link = ({ url, className, children }) => {
  return (
    <a
      className={className}
      href={url}
      target="_blank"
      rel="noopener noreferrer"
    >
      {children}
    </a>
  );
};
