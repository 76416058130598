import React from 'react';
import Img from 'gatsby-image';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import { BLOCKS, MARKS, INLINES } from '@contentful/rich-text-types';
import Layout from '../components/layout';
import { Section, SimpleButton, TemplateContactSection } from '../components/section';
import TemplateAssetMedia from './template-asset-media';
import TemplateAssetLink from './template-asset-link';
import { HeaderText1, TagText } from '../components/text';
import { BusinessCard } from '../components/card';
import formatDate from '../utils/date-format';
import {
  YOUTUBE_VIDEO_URL_FORMAT,
  VIMEO_VIDEO_URL_FORMAT,
  KATSOMO_VIDEO_URL_FORMAT,
} from '../utils/constants';
import { useStaticQuery, graphql } from 'gatsby';
import createTranslate from '../utils/translator-provider';
import ShareButtons from '../components/social/share-component';
import {H2} from './text'
import ExternalVideo from '../components/embedded/external-video';

const widthConstraintStyle = 'w-full';

export default ({
  layoutTitle,
  description,
  path,
  type,
  title,
  tag,
  createdAt,
  updatedAt,
  previewImage,
  contentJSON,
  contacts,
  contactFormEmbeddedContent,
  lang,
  shareOnSocialMedia,
  publicURL,
}:any) => {

 const updateDate = typeof updatedAt === "undefined" ? false : updatedAt

const data = useStaticQuery(graphql`
  query {
    page: contentfulPage(name: { eq: "Artikkelit yms template" }) {
      title
      translations {
        key
        value {
          value
        }
      }
    }
  }
`);

const translate = createTranslate(data?.page?.translations);

  return (
    <Layout
      title={layoutTitle}
      description={description}
      path={path}
      type={type}
      published={createdAt}
      publicURL={publicURL}
    >
      <Section className="py-20">
        <Preview
          title={title}
          tag={tag}
          createdAt={createdAt}
          updatedAt={updateDate}
          editedText={lang ? translate('template.editedEnglish')  : translate('template.edited')  }
          previewImage={previewImage}
          shareOnSocialMedia={shareOnSocialMedia}
        />
        <Content contentJSON={contentJSON} />
        <Contacts contacts={contacts} />
      </Section>
      <ContactSection embeddedContent={contactFormEmbeddedContent} />
    </Layout>
  );
};

const Preview = ({ title, tag, createdAt, previewImage, updatedAt, editedText, shareOnSocialMedia }) => {
  const updatedDate = formatDate(new Date(updatedAt))
  const createDate = formatDate(new Date(createdAt))

  return (
    <>
      <CenterAlign>
        <HeaderText1 className="w-full max-w-800px ">{title}</HeaderText1>
      </CenterAlign>
      <CenterAlign>
        <div
          className={`${widthConstraintStyle} mt-2 flex flex-row center-items`}
        >
          <TagText>{tag}</TagText>
          <TagText className="mx-4">|</TagText>
        {updatedAt && updatedDate !== createDate ?  <TagText className="mr-2"> ( {editedText}  {updatedDate} ) </TagText> : null}
          <TagText> {formatDate(new Date(createdAt))}</TagText>
        </div>
      </CenterAlign>
        <CenterAlign>
          <div className="w-full max-w-800px">
            <ShareButtons
              shareOnSocialMedia={shareOnSocialMedia}
              title={title}
            />
          </div>
        </CenterAlign>
      {previewImage ? (
        <Img
          className="my-12"
          fluid={
            previewImage.localFile
              ? previewImage.localFile.childImageSharp.fluid
              : previewImage.fluid
          }
          alt={previewImage.title}
        />
      ) : null}
    </>
  );
};

const Content = ({ contentJSON, locales='fi-FI' }) => {
  return (
    <div className="flex flex-col text-left gap-6">
      {documentToReactComponents(contentJSON, {
        renderMark: {
          [MARKS.BOLD]: text => <span className="font-extrabold">{text}</span>,

          [MARKS.ITALIC]: text => (
            <span className="italic">{text}</span>
          ),
        },
        renderNode: {
          [BLOCKS.HEADING_2]: (_node, children) => (
            <CenterAlign>
              <H2
                className={`${widthConstraintStyle} leading-tight`}
              >
                {children}
              </H2>
            </CenterAlign>
          ),

          [BLOCKS.HEADING_3]: (_node, children) => (
            <CenterAlign>
              <h3
                className={`${widthConstraintStyle}  leading-relaxed text-xl font-semi-bold`}
              >
                {children}
              </h3>
            </CenterAlign>
          ),

          [BLOCKS.PARAGRAPH]: (_node, children) => {
          return (
            <CenterAlign>
              <div
                className={`${widthConstraintStyle} font-book text-lg leading-loose whitespace-pre-wrap`}
              >
                {children}
              </div>
            </CenterAlign>
          )},
          [BLOCKS.LIST_ITEM ]: (_node, children) => {
            const newChildren = (children || []).map(x => <p>{x.props.children.props.children}</p>)
            return (
              <li>{newChildren}</li>
            );
          },
          [BLOCKS.UL_LIST]: (_node, children) => {
            return (
              <CenterAlign>
                <ul
                  className={`${widthConstraintStyle} list-circle ml-4 leading-normal`}
                >
                  {children}
                </ul>
              </CenterAlign>
            );
          },

          [BLOCKS.EMBEDDED_ASSET]: node => {
            return (
              <CenterAlign>
                <TemplateAssetMedia
                  file={node.data.target.fields.file[locales]}
                  title={node.data.target.fields.title[locales]}
                  className="my-10 w-full"
                />
              </CenterAlign>
            );
          },

          [BLOCKS.EMBEDDED_ENTRY]: node => {
            const allRows = node.data.target.fields.content[locales]
              .split('\n')
              .map(r => r.split(';'));

            const headers =
              allRows.length > 0
                ? allRows
                    .slice(0, 1)
                    .flatMap(h => h)
                    .map(h => h.trim())
                : [];
            const rows =
              allRows.length > 1
                ? allRows
                    .slice(1, allRows.length)
                    .flatMap(v => v)
                    .map(v => v.trim())
                : [];

            const cellStyle =
              'py-5px px-2 flex flex-col justify-start text-right bg-slate-bright';

            return (
              <div className="w-full mt-10 flex flex-row justify-center">
                <div
                  className={`${widthConstraintStyle} grid gap-1`}
                  style={{
                    gridTemplateColumns: `repeat(${headers.length}, minmax(0, 1fr))`,
                  }}
                >
                  {headers.map((h, i) => {
                    return (
                      <div key={`tableHeader_${h}_${i}`} className={cellStyle}>
                        <span className="font-semi-bold text-sm leading-loose">
                          {h}
                        </span>
                      </div>
                    );
                  })}

                  {rows.map((v, i) => {
                    return (
                      <div key={`tableRow_${v}_${i}`} className={cellStyle}>
                        <span className="font-book text-lg leading-loose">
                          {v}
                        </span>
                      </div>
                    );
                  })}
                </div>
              </div>
            );
          },

          [INLINES.HYPERLINK]: (node, children) => {
            if(
                (node.data.uri).includes(YOUTUBE_VIDEO_URL_FORMAT) ||
                (node.data.uri).includes(VIMEO_VIDEO_URL_FORMAT) ||
                (node.data.uri).includes(KATSOMO_VIDEO_URL_FORMAT)
            ) {
              return <div className="h-124 w-full relative block pb-8">
                <ExternalVideo
                  className="h-124 w-full absolute top-0 left-0"
                  src={node.data.uri}
                  allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture; fullscreen"
                  allowFullScreen
                />
              </div>
            }
            else {
              return <a
                target="_self"
                rel="noopener noreferrer"
                className="font-book text-red leading-loose underline"
                href={node.data.uri}
              >
                <SimpleButton>{children}</SimpleButton>
              </a>}
          },

          [INLINES.ASSET_HYPERLINK]: (node, children) => {
            return (
              <TemplateAssetLink
                file={node.data.target.fields.file[locales]}
                className="font-book text-red leading-loose underline"
              >
                {children}
              </TemplateAssetLink>
            );
          },
        },
      })}
    </div>
  );
};

/** Generic rich text paragraph */
export const SimpleParagraphContent = Content;

const Contacts = ({ contacts }) => {
  return contacts ? (
    <CenterAlign>
      <div className={`${widthConstraintStyle} mt-10`}>
        <div className="grid grid-cols-1 row-gap-10">
          {contacts.map((c, i) => {
            return (
              <BusinessCard
                key={i}
                image={c.personImage}
                firstName={c.firstName}
                lastName={c.lastName}
                title={c.title}
                region={c.regionName}
                phoneNumber={c.phoneNumber}
                email={c.email}
              />
            );
          })}
        </div>
      </div>
    </CenterAlign>
  ) : null;
};

const ContactSection = ({ embeddedContent }) => {
  return embeddedContent ? (
    <TemplateContactSection
      embeddedContent={embeddedContent}
      className="h-1050px sm:h-880px max-w-590px"
    />
  ) : null;
};

const CenterAlign = ({ children }) => {
  return <div className="w-full flex flex-row justify-center">{children}</div>;
};
